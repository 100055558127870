@import 'setup.css';
@import 'hint.css';

$color1: #2B54D0;
$color2: #1A8896;
$color3: #01B0C5;
$color4: #53EBF3;
$color5: #B4B9BC;
$color6: #FDFDFD;

$baseFont: "proxima-nova", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$headerFont: "brandon-grotesque", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

body { margin: 0 }
img { max-width: 100% }
svg { max-height: 100% }

html {
  font-size: 62.5%
}

input,
select,
textarea,
fieldset {
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
}

label {
  vertical-align: middle;
}

input[type=text],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week] {
  /*height: 2.25rem;*/
  padding: .5rem .5rem;
  vertical-align: middle;
  -webkit-appearance: none;
}

/*select {
  line-height: 1.75;
  padding: .5rem .5rem;
}*/

select:not([multiple]) {
  /*height: 2.25rem;*/
  vertical-align: middle;
}

textarea {
  line-height: 1.75;
  padding: .5rem .5rem;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

th {
  text-align: left;
  font-weight: bold;
}

th,
td {
  padding: .25rem 1rem;
  line-height: inherit;
}

th { vertical-align: bottom }
td { vertical-align: top }

body {
  font-size: 1.6rem;
  line-height: 1.7;
  font-family: $baseFont;
  font-weight: 300;
  background: #EAEEF1;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headerFont;
  font-weight: bold;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: .5em;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

pre, code, samp {
  font-family: 'Source Code Pro', Consolas, monospace;
  font-size: inherit;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow-x: scroll;
}

h1 { font-size: 9rem; letter-spacing: -10px; margin-bottom: 1.5rem; }
h2 { font-size: 3rem }
h3 { font-size: 2.7rem }
h4 { font-size: 2.3rem }
h5 { font-size: .875rem }
h6 { font-size: .75rem }

body {
  color: #111;
  background-color: #EAEEF1;
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 1024px) {
  body {
    display: block;
  }
}

a {
  color: #0074d9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

pre, code {
  background-color: transparent;
  border-radius: 3px;
}

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

header.top {
  background: linear-gradient(45deg, #354052, #28313D);
  color: white;
  flex: 1 0 180px;
  position: relative;
  .logo {
    background: #4B73EB;
    display: inline-block;
    width: 100%;
    padding: 1.5rem 1.8rem;
    margin-bottom: 2rem;
    h1 {
      margin: 0;
    }
  }
  .details {
    text-align: center;
    padding: 1.5rem 1.8rem;
    padding-bottom: 125px;
    p {
      line-height: 1.6;
    }
    p.explain {
      display: inline-block;
      margin-top: 20px;
      width: 100%;
      text-transform: uppercase;
      font-family: $headerFont;
      padding: 14px 18px;
      font-size: 1.2rem;
      line-height: 1.4;
      background: #4B73EB;
      cursor: pointer;
    }
  }
  .attrib {
    padding: 1.5rem 1.8rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #28313D;
    text-align: center;
    p {
      text-align: center;
    }
  }
}

@media (max-width: 1024px) {
  header.top {
    width: 100%;
    .logo {
      text-align: center;
    }
    .details {
      padding-bottom: 125px;
    }
  }
}

#content {
  flex: 1 0 calc(100% - 180px);
}

section.appBody {
  display: flex;
  min-height: 100vh;
  .formControl {
    flex: 1 0 30%;
    padding: 1.8rem 2.5rem;
    background: white;
  }
  .displayBody {
    flex: 1 0 70%;
    padding: 1.8rem 2.5rem;
  }
}

@media only screen 
  and (max-width: 1024px) {
  section.appBody {
    display: block;
  }
}

.inputRow {
  display: block;
  margin: 2rem 0;
  label {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: $headerFont;
    color: #9E9E9E;
    letter-spacing: 1px;
  }
  input, select {
    display: block;
    border: 3px solid #D2DAE2;
    padding: 1.3rem;
    width: 100%;
    margin: 1rem 0;
    font-size: 1.8rem;
    border-radius: 5px;
    background: white;
  }
  select {
    appearance: none;
    position: relative;
    background: url(../images/Arrow-Down-icon.png);
    background-size: 25px;
    background-position: 95% center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=submit].submit {
  font-size: 1.4rem;
  background: #354052;
  text-transform: uppercase;
  font-family: $headerFont;
  border: 2px solid #354052;
  color: white;
  width: 100%;
  padding: 1.4rem 0;
  transition: all 0.3s;
  letter-spacing: 1px;
  /*border-radius: 5px;*/
  &:hover {
    background: rgba(0,0,0,0.05);
    border: 2px solid rgba(0,0,0,0.4);
    color: rgba(0,0,0,0.4);
  }
}

section.equationDisplay {
  margin-top: 2.5rem;
  padding-bottom: 3.5rem;
  border-bottom: 1px solid #D2DAE2;
  code {
    padding: 5rem 2.5rem;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2); 
    border: 1px solid rgba(0,0,0,0.2); 
    background: white;
    color: #888;
    display: block;
    font-weight: 300;
    font-family: "source-code-pro",sans-serif;
  }
}

header.controls {
  display: flex;
  .itemClick {
    display: inline-block;
    padding: 1.2rem;
    font-size: 1.2rem;
    background: #354052;
    color: white;
    cursor: pointer;
    font-family: $headerFont;
    letter-spacing: 1px;
    margin-right: 10px;
    text-transform: uppercase;
  }
}

section.itemList {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    padding: 10px 0;
    li {
      margin-right: 10px;
      margin-bottom: 10px;
      width: calc(25% - 10px);
      height: 100px;
      background: #4B73EB;
      transition: all 0.7s;
      border-radius: 3px;
    }
  }
}

@media (max-width: 1024px) {
  section.itemList ul {
    display: block;
    li {
      float: left;
    }
  }
}

.hint {
  background: #333;
  border-radius: 50%;
  width: 20px;
  height: 21px;
  text-align: center;
  color: white;
  padding: 0 2px 0 1px;
  letter-spacing: -1px;
  &:after {
    letter-spacing: 0;
  }
}

@media (max-width: 1024px) {
  .hint {
    display: none;
  }
}

.lightbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: none;
  .lightboxInner {
    text-align: center;
    width: 80%;
    height: auto;
    background: white;
    margin: auto;
    border-radius: 5px;
    position: relative;
    .closeButton {
      border-radius: 50%;
      background: white;
      position: absolute;
      font-size: 2.2rem;
      margin: 0;
      height: 35px;
      width: 35px;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
    header.inner {
      background: $color1;
      padding: 5px 0 10px;
      color: white;
      margin-bottom: 15px;
    }
    section.content {
       padding: 1rem 3rem 2rem;
    }
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2rem;
      margin: 0;
    }
    h4 {
      font-size: 1.8rem;
    }
    code {
      display: block;
      font-size: 1.1rem;
      text-align: left;
      width: 90%;
      margin: 0 auto;
      padding: 10px;
      margin-bottom: 25px;
      box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
    }
    p {
      line-height: 1.4;
    }
  }
}

#lightbox.show {
  display: flex;
}

@media (max-width: 1024px) {
  .lightbox {
    position: absolute;
    flex-flow: column wrap;
  }
  .lightbox .lightboxInner {
    height: auto;
    margin: 0 auto;
    top: 25px;
    width: 90%;
  }
}


